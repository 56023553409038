export const repositoryItems = {
  "post-code": {
    type: "post-code",
    title: "Post Code",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "post-code" },
    headers: [
      {
        text: "Post Code",
        value: "postCode",
      },
      {
        text: "Division",
        value: "division",
      },
      {
        text: "Division Code",
        value: "divisionCode",
      },
      {
        text: "District",
        value: "district",
      },
      {
        text: "District Code",
        value: "districtCode",
      },
      {
        text: "Thana",
        value: "thana",
      },
      {
        text: "Thana Code",
        value: "thanaCode",
      },
      {
        text: "Sub-office",
        value: "subOffice",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  customer: {
    type: "customer",
    title: "Customer Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "customer" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  "ip-type": {
    type: "ip-type",
    title: "IP Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "ip-type" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  onboarding: {
    type: "onboarding",
    title: "Onboarding Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "onboarding" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  profession: {
    type: "profession",
    title: "Professions",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "profession" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Category",
        value: "category",
      },
      {
        text: "Sub Category",
        value: "subCategory",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  "risk-grading-product": {
    type: "risk-grading-product",
    title: "Risk Grading Product",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "risk-grading-product" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  "risk-country": {
    type: "risk-country",
    title: "Risk Country Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "risk-country" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  "source-of-fund": {
    type: "source-of-fund",
    title: "Source of Fund Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "source-of-fund" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  "transaction-amount": {
    type: "transaction-amount",
    title: "Transaction Amount Types",
    icon: "mdi-file-document",
    routeName: "repository.item",
    routeParams: { item: "transaction-amount" },
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Score",
        value: "score",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
  terms: {
    type: "terms",
    title: "Terms & Condition",
    icon: "mdi-file-document",
    routeName: "repository.terms",
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Channel",
        value: "channel",
      },
      {
        text: "Terms",
        value: "terms",
      },
      {
        text: "Actions",
        width: 120,
        value: "actions",
        sortable: false,
      },
    ],
  },
};
