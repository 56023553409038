<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">
        {{ itemClass && itemClass.title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn color="primary" dark class="mb-2" @click.stop="createItem()" v-if="canCreate">
        <v-icon>mdi-plus</v-icon> New Item
      </v-btn>
    </v-toolbar>

    <v-row v-if="itemClass" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="itemClass.headers"
          :items="dataList"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar color="lighten-5" flat>
              <v-text-field
                v-model="search"
                solo
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Filter items"
              />
            </v-toolbar>
            <v-divider class="mb-5" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click.stop="editItem(item)" v-if="canEdit(item)">
              <v-icon color="purple">mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click.stop="deleteConfirmation(item)" v-if="canDelete(item)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <h4 v-if="!loading" class="text-h4 red--text font-weight-regular my-5">
              No entry found
            </h4>
            <v-progress-circular
              class="my-16"
              v-else
              indeterminate
              size="50"
              width="5"
              color="grey"
            />
            <v-btn color="primary" v-if="!loading" @click="fetchList()" class="my-5">
              Refresh
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800">
      <RepoItemForm
        :action="action"
        :item="selectedItem"
        :item-class="itemClass"
        @dismiss="action = null"
        @updated="itemUpdated"
        :key="action + (selectedItem && selectedItem.id)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { repositoryItems } from "@/constants/repositoryItems";
import * as api from "@/constants/api";

export default {
  name: "RepositoryItem",
  metaInfo: { title: "Repository Items" },
  components: {
    RepoItemForm: () => import("@/views/components/Repository/RepoItemForm"),
  },
  data() {
    return {
      loading: false,
      itemClass: null,
      dataList: [],
      headers: [],
      search: null,
      action: null,
      selectedItem: null,
    };
  },
  computed: {
    dialog: {
      get() {
        return !!this.action;
      },
      set(v) {
        if (!v) this.action = null;
      },
    },
    canCreate() {
      return this.verifyPrivilege(api.REPO_ITEM_CREATE.replace(":item:", this.itemClass.type));
    },
  },
  watch: {
    "$route.params.item": {
      deep: true,
      immediate: true,
      handler: function () {
        this.updateCurrentItem();
      },
    },
  },
  methods: {
    fetchList() {
      if (this.itemClass && this.itemClass.type) {
        this.loading = true;
        this.$axios
          .get(api.REPO_ITEM_LIST.replace(":item:", this.itemClass.type))
          .then((response) => {
            if (response && response.data && response.data.code === 0) {
              this.dataList = response.data.data;
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((err) => {
            this.dataList = [];
            this.$iziToast.showError(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    createItem() {
      this.selectedItem = null;
      this.action = "create";
    },
    editItem(item) {
      this.selectedItem = item;
      this.action = "edit";
    },
    updateCurrentItem() {
      this.dataList = [];
      this.action = null;
      this.search = null;
      this.selectedItem = null;
      this.itemClass = repositoryItems[this.$route.params.item];
      if (!this.itemClass) {
        this.$router.replace({ name: "dashboard" });
      } else {
        this.fetchList();
      }
    },
    deleteConfirmation(item) {
      this.$iziToast.askConfirmation().then((confirm) => {
        if (confirm) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem(item) {
      if (item) {
        this.loading = true;
        this.$axios
          .post(
            api.REPO_ITEM_DELETE.replace(":item:", this.itemClass.type).replace(
              ":typeId:",
              item.id ? item.id : item.code
            )
          )
          .then((response) => {
            if (response && response.data && response.data.code === 0) {
              this.$iziToast.success({
                title: "Item Deleted",
              });
              this.fetchList();
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((err) => {
            this.$iziToast.showError(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    itemUpdated() {
      this.action = null;
      this.fetchList();
    },
    canEdit(item) {
      return this.verifyPrivilege(
        api.REPO_ITEM_UPDATE.replace(":item:", this.itemClass.type).replace(
          ":typeId:",
          item.id ? item.id : item.code
        )
      );
    },
    canDelete(item) {
      return this.verifyPrivilege(
        api.REPO_ITEM_DELETE.replace(":item:", this.itemClass.type).replace(
          ":typeId:",
          item.id ? item.id : item.code
        )
      );
    },
  },
};
</script>
